<template>
    <div>
        <datatable stt="true" :columns="columnsClient" :rows="data">
            <th slot="thead-tr-o">
                Thao Tác
            </th>
            <template slot="tbody-tr-o" slot-scope="props">
                <td>
                    <button
                        v-if="props.row.is_confirm == 1 && props.row.status == 2"
                        @click="confirmForceRefund({ log: props.row })"
                        type="button"
                        class="btn btn-orange btn-xs mr-2"
                        data-toggle="modal"
                        href="#level-modal"
                    >
                        <i class="fa fa-dollar mr-1"></i>
                        Xác nhận không trùng
                    </button>

                    <button
                        v-else
                        :disabled="props.row.is_confirm == 1"
                        @click="confirmRefund(props.row)"
                        type="button"
                        class="btn btn-orange btn-xs mr-2"
                        data-toggle="modal"
                        href="#level-modal"
                    >
                        <i class="fa fa-dollar mr-1"></i>
                        Hoàn tiền
                    </button>
                    <button
                        :disabled="props.row.is_confirm == 1"
                        @click="editRefund(props.row)"
                        data-target="#update-refund"
                        data-toggle="modal"
                        type="button"
                        class="btn btn-dark-blue btn-xs mr-2"
                    >
                        <i class="fa fa-edit"></i>
                        Chỉnh Sửa
                    </button>
                    <button
                        @click="detailRefund(props.row)"
                        data-target="#modal-detail-refund"
                        data-toggle="modal"
                        type="button"
                        class="btn btn-danger btn-xs"
                    >
                        <i class="fa fa-list"></i>
                        Chi tiết
                    </button>
                </td>
            </template>
        </datatable>
        <div
            id="update-refund"
            class="modal fade"
            tabindex="-1"
            role="dialog"
            aria-labelledby="updateRefundUser"
            aria-hidden="true"
            style="display: none;"
        >
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Cập Nhật Thông Tin</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form @submit.stop.prevent="updateRefundUser">
                        <div class="modal-body">
                            <div class="form-group">
                                <label>Số tiền hoàn:</label>
                                <div class="input-group">
                                    <input
                                        v-model.number="selectedRefund.coin"
                                        type="number"
                                        placeholder="Số tiền hoàn"
                                        class="form-control"
                                        name="example-email"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text">
                                            {{ currency }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Ghi chú:</label>
                                <textarea
                                    type="text"
                                    v-model="selectedRefund.description"
                                    required
                                    class="form-control"
                                    rows="3"
                                ></textarea>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default waves-effect" data-dismiss="modal">
                                Hủy bỏ
                            </button>
                            <button type="submit" :class="['btn btn-primary waves-effect waves-light']">
                                Cập Nhật
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div id="modal-detail-refund" class="modal fade" tabindex="-1" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="modal-title">Chi tiết</div>
                    </div>
                    <div class="modal-body">
                        <div class="card" v-for="(item, index) in listDetailRefund" :key="index">
                            <refunddetailuser :item="item"></refunddetailuser>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import datatable from "../../../components/datatable/datatable"
import { formatNumber, catchError } from "../../../helpers"
import { getDetailRefund, updateRefund, confirmRefund, confirmForceRefund } from "../../../api/admin"
import refunddetailuser from "./refund-detail-user"

export default {
    name: "refund-list-user",
    components: {
        datatable,
        refunddetailuser
    },
    props: {
        data: Array
    },
    data() {
        return {
            columnsClient: [
                { label: "Trạng thái", field: "status_html", html: true },
                { label: "UserName", field: "username" },
                { label: "Đối tượng", field: "object_id", fb_link: true },
                { label: "Tiền hoàn", field: "coin", numeric: true },
                { label: "Tool", field: "tool", html: true },
                { label: "Ghi chú", field: "description", html: true },
                { label: "Ngày Tạo", field: "created_at", timeago: true }
            ],
            listDetailRefund: [],
            selectedRefund: {}
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        isMobile() {
            return this.$store.state.app.isMobile
        },
        site() {
            return this.$store.state.site.site
        },
        currency() {
            return this.$store.state.site.site.currency || "Xu"
        }
    },
    methods: {
        editRefund: function(row) {
            this.selectedRefund = Object.assign({}, row)
        },
        detailRefund: async function(rows) {
            const param = rows.orders_id ? "?orders_id=" + rows.orders_id : ""
            const data = await getDetailRefund(param)
            if (!data.success) {
                return this.$swal("Lỗi", "Không tải được chi tiết lịch sử, vui lòng thử lại sau!", "error")
            }

            if (!data.data.length) {
                return this.$swal("Thông báo", "Không có dữ liệu chi tiết", "info")
            }

            this.listDetailRefund = data.data.map(item => {
                item.type_html = item.type.toUpperCase()
                item.warranty =
                    item.is_warranty === 1
                        ? '<span class="badge badge-success">Có bảo hành</span>'
                        : '<span class="badge badge-info">Không bảo hành</span>'
                item.list_messages_html = item.list_messages ? JSON.parse(item.list_messages).join("\n") : ""
                item.price_perText =
                    "<span class='badge text-light badge-pill badge-info'>" + formatNumber(item.price_per) + "</span>"
                item.prices =
                    "<span class='badge text-light badge-pill badge-primary'>" + formatNumber(item.prices) + "</span>"
                return item
            })
        },
        updateRefundUser: async function() {
            let postData = {
                coin: this.selectedRefund.coin,
                description: this.selectedRefund.description
            }
            let data = await updateRefund(this.selectedRefund.id, postData)
            if (data.status === 200 && data.success) {
                this.$swal("Thành Công", "Cập nhật thông tin hoàn tiền thành công", "success")
                this.$store.commit("APP_RELOAD")
                // eslint-disable-next-line no-undef
                $("#update-refund").modal("hide")
            } else {
                this.$swal("Lỗi", catchError(data), "error")
            }
        },
        confirmRefund: async function(log) {
            if (log.is_create_error) {
                const swalConfig = {
                    title: "Xác nhận hoàn tiền",
                    text: "Vui lòng kiểm tra id " + log.id + " đã tạo bên MFB hay chưa, trước khi xác nhận hoàn tiền",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    cancelButtonText: "Hủy",
                    confirmButtonText: "Xác nhận"
                }
                this.$swal.fire(swalConfig).then(async result => {
                    if (result.value) {
                        this.reconfirmRefund(log.id)
                    }
                })
            } else {
                this.reconfirmRefund(log.id)
            }
        },
        reconfirmRefund: async function(log_id) {
            let data = await confirmRefund(log_id)
            if (data.status === 200 && data.success) {
                this.$swal("Thành Công", "Hoàn tiền thành công", "success")
                this.$store.commit("APP_RELOAD")
            } else {
                this.$swal("Lỗi", catchError(data), "error")
            }
        },
        confirmForceRefund: async function ({ log }) {
            if (log.is_create_error) {
                const swalConfig = {
                    title: "Xác nhận hoàn tiền",
                    text: "Vui lòng kiểm tra id " + log.id + " đã tạo bên MFB hay chưa, trước khi xác nhận hoàn tiền",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    cancelButtonText: "Hủy",
                    confirmButtonText: "Xác nhận"
                }
                this.$swal.fire(swalConfig).then(async result => {
                    if (result.value) {
                        this.reconfirmForceRefund({ log_id: log.id, force: 1 })
                    }
                })
            } else {
                this.reconfirmForceRefund({ log_id: log.id, force: 1 })
            }
        },
        reconfirmForceRefund: async function ({ log_id, force }) {
            let data = await confirmForceRefund({ id: log_id, force })
            if (data.status === 200 && data.success) {
                this.$swal("Thành Công", "Hoàn tiền thành công", "success")
                this.$store.commit("APP_RELOAD")
            } else {
                this.$swal("Lỗi", catchError(data), "error")
            }
        },
    }
}
</script>
