<template>
    <div class="card-body">
        <div class="row">
            <div class="col-12" v-if="item.user_name">
                <span>
                    <span>Username: </span>
                    <span class="font-bold">{{ item.user_name }}</span>
                </span>
            </div>
            <div class="col-12">
                <span>
                    ID Seeding:
                    <a :href="`https://www.facebook.com/${item.object_id}`" target="_blank">{{ item.object_id }}</a>
                </span>
            </div>
            <div class="col-md-6">
                <span>
                    <span>Loại: </span>
                    <span class="font-bold">{{ item.type_html }}</span>
                </span>
            </div>
            <div class="col-md-6">
                <span>
                    <span v-html="item.warranty"></span>
                </span>
            </div>
            <div class="col-md-6">
                <span>Tồng tiền: </span>
                <span v-html="item.prices"></span>
            </div>
            <div class="col-md-6">
                <span>
                    <span>Số lượng: </span>
                    <span class="badge badge-warning">{{ item.quantity }}</span>
                </span>
            </div>
            <div class="col-md-6">
                <span>
                    <span>SL đã tăng:</span>
                    <span class="badge badge-success">{{
                        item.is_warranty == "0" ? item.count_is_run : item.count_success
                    }}</span>
                </span>
            </div>
            <div class="col-md-6">
                <span>
                    <span>SL hoàn: </span>
                    <span class="badge badge-danger">{{ item.count_refund }}</span>
                </span>
            </div>
            <div class="col-md-6">
                <span>
                    <span>Gía mỗi lượt: </span>
                    <span v-html="item.price_perText"></span>
                </span>
            </div>
            <div class="col-md-6">
                <span>
                    <span>Tổng tiền hoàn: </span>
                    <span class="badge badge-dark">{{ (item.count_refund * item.price_per) | formatNumber }}</span>
                </span>
            </div>
            <div class="col-md-6">
                <span>
                    <span>Trạng thái :</span>
                    <span
                        :class="[
                            'badge text-light',
                            item.status == '1'
                                ? 'badge-info'
                                : item.status == 2 && item.is_refund
                                ? 'badge-success'
                                : item.status == '0' && !item.object_not_exist && !item.is_hidden && item.count_is_run
                                ? 'badge-success'
                                : new Date() > new Date(item.time_expired.replace(' ', 'T'))
                                ? 'badge-danger'
                                : item.object_not_exist === '1'
                                ? 'badge-danger'
                                : item.is_hidden === '1'
                                ? 'badge-danger'
                                : ''
                        ]"
                        >{{
                            item.status == "1"
                                ? "Chạy xong"
                                : item.status == 2 && item.is_refund
                                ? "Hoàn thành"
                                : item.status == "0" && !item.object_not_exist && !item.is_hidden && item.count_is_run
                                ? "Đang chạy"
                                : new Date() > new Date(item.time_expired.replace(" ", "T"))
                                ? "Đã hết hạn"
                                : item.object_not_exist === "1"
                                ? "ID Lỗi"
                                : item.is_hidden === "1"
                                ? "Bị ẩn"
                                : ""
                        }}
                    </span>
                </span>
            </div>
            <div class="col-md-6">
                <span>
                    <span>Ngày tạo: </span>
                    <span>{{ item.created_at | timeAgo }} </span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "refund-detail-user",
    props: {
        item: Object
    },
    components: {}
}
</script>

<!-- order.checkStatus === 'Chờ hoàn tiền'
              ? 'badge-info'
              : order.checkStatus === 'Đang chạy'
                ? 'badge-primary'
                : order.checkStatus === 'Đã hoàn tiền'
                  ? 'badge-success'
                  : order.checkStatus === 'Chạy xong'
                    ? 'badge-success'
                    : order.checkStatus === 'Hoàn thành'
                      ? 'badge-success'
                      : 'badge-danger'
                      status_order (data) {
      if (data.status === '1') {
        return 'Chạy xong'
      } else if (data.status === 2 && data.is_refund) {
        return 'Hoàn thành'
      } else if (
        data.status === '0' ||
        (!data.object_not_exist &&
          !data.is_hidden &&
          data.count_is_run < data.quantity &&
          new Date() < new Date(data.time_expired.replace(' ', 'T')))
      ) {
        return 'Đang chạy'
      } else if (new Date() > new Date(data.time_expired.replace(' ', 'T'))) {
        return 'Đã hết hạn'
      } else if (data.object_not_exist === '1') {
        return 'Id lỗi'
      } else if (data.is_hidden === '1') {
        return 'Bị ẩn'
      }
    }, -->
