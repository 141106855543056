var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[(_vm.item.user_name)?_c('div',{staticClass:"col-12"},[_c('span',[_c('span',[_vm._v("Username: ")]),_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.item.user_name))])])]):_vm._e(),_c('div',{staticClass:"col-12"},[_c('span',[_vm._v(" ID Seeding: "),_c('a',{attrs:{"href":`https://www.facebook.com/${_vm.item.object_id}`,"target":"_blank"}},[_vm._v(_vm._s(_vm.item.object_id))])])]),_c('div',{staticClass:"col-md-6"},[_c('span',[_c('span',[_vm._v("Loại: ")]),_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.item.type_html))])])]),_c('div',{staticClass:"col-md-6"},[_c('span',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.item.warranty)}})])]),_c('div',{staticClass:"col-md-6"},[_c('span',[_vm._v("Tồng tiền: ")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.item.prices)}})]),_c('div',{staticClass:"col-md-6"},[_c('span',[_c('span',[_vm._v("Số lượng: ")]),_c('span',{staticClass:"badge badge-warning"},[_vm._v(_vm._s(_vm.item.quantity))])])]),_c('div',{staticClass:"col-md-6"},[_c('span',[_c('span',[_vm._v("SL đã tăng:")]),_c('span',{staticClass:"badge badge-success"},[_vm._v(_vm._s(_vm.item.is_warranty == "0" ? _vm.item.count_is_run : _vm.item.count_success))])])]),_c('div',{staticClass:"col-md-6"},[_c('span',[_c('span',[_vm._v("SL hoàn: ")]),_c('span',{staticClass:"badge badge-danger"},[_vm._v(_vm._s(_vm.item.count_refund))])])]),_c('div',{staticClass:"col-md-6"},[_c('span',[_c('span',[_vm._v("Gía mỗi lượt: ")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.item.price_perText)}})])]),_c('div',{staticClass:"col-md-6"},[_c('span',[_c('span',[_vm._v("Tổng tiền hoàn: ")]),_c('span',{staticClass:"badge badge-dark"},[_vm._v(_vm._s(_vm._f("formatNumber")((_vm.item.count_refund * _vm.item.price_per))))])])]),_c('div',{staticClass:"col-md-6"},[_c('span',[_c('span',[_vm._v("Trạng thái :")]),_c('span',{class:[
                        'badge text-light',
                        _vm.item.status == '1'
                            ? 'badge-info'
                            : _vm.item.status == 2 && _vm.item.is_refund
                            ? 'badge-success'
                            : _vm.item.status == '0' && !_vm.item.object_not_exist && !_vm.item.is_hidden && _vm.item.count_is_run
                            ? 'badge-success'
                            : new Date() > new Date(_vm.item.time_expired.replace(' ', 'T'))
                            ? 'badge-danger'
                            : _vm.item.object_not_exist === '1'
                            ? 'badge-danger'
                            : _vm.item.is_hidden === '1'
                            ? 'badge-danger'
                            : ''
                    ]},[_vm._v(_vm._s(_vm.item.status == "1" ? "Chạy xong" : _vm.item.status == 2 && _vm.item.is_refund ? "Hoàn thành" : _vm.item.status == "0" && !_vm.item.object_not_exist && !_vm.item.is_hidden && _vm.item.count_is_run ? "Đang chạy" : new Date() > new Date(_vm.item.time_expired.replace(" ", "T")) ? "Đã hết hạn" : _vm.item.object_not_exist === "1" ? "ID Lỗi" : _vm.item.is_hidden === "1" ? "Bị ẩn" : "")+" ")])])]),_c('div',{staticClass:"col-md-6"},[_c('span',[_c('span',[_vm._v("Ngày tạo: ")]),_c('span',[_vm._v(_vm._s(_vm._f("timeAgo")(_vm.item.created_at))+" ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }