<template>
    <div>
        <datatable stt="true" :columns="columnsClient" :rows="data_success"> </datatable>
    </div>
</template>
<script>
import datatable from "../../../components/datatable/datatable"
export default {
    name: "refund-history",
    components: {
        datatable
    },
    props: {
        data_success: Array
    },
    data() {
        return {
            columnsClient: [
                { label: "Trạng thái", field: "status_html", html: true },
                { label: "UserName", field: "username" },
                { label: "Đối tượng", field: "object_id", fb_link: true },
                { label: "Trước khi hoàn", field: "old_coin", html: true },
                { label: "Sau khi hoàn", field: "new_coin", html: true },
                { label: "Ghi chú", field: "description", html: true },
                { label: "Ngày Tạo", field: "created_at", timeago: true }
            ],
            listDetailRefund: [],
            selectedRefund: {}
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        isMobile() {
            return this.$store.state.app.isMobile
        },
        site() {
            return this.$store.state.site.site
        },
        currency() {
            return this.$store.state.site.site.currency || "Xu"
        }
    }
}
</script>
