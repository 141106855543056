<template>
    <div>
        <h4 class="bold text-uppercase">Quản lý hoàn tiền</h4>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a
                    class="nav-link active"
                    @click="handleShowPanel('list')"
                    id="home-tab"
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    >Danh sách hoàn tiền</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    @click="handleShowPanel('logs')"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                    >Lịch sử đã hoàn tiền</a
                >
            </li>
        </ul>
        <div class="tab-content mt-3" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div class="row mb-3">
                    <div class="col-md-4 col-12">
                        <div class="row align-items-center">
                            <div class="col-md-auto col-12">
                                <h6 class="bold mb-0">Tìm theo username:</h6>
                            </div>
                            <div class="col-md col-12 mt-2 mt-md-0">
                                <input
                                    @change="getListRefund()"
                                    class="form-control input-light"
                                    type="text"
                                    placeholder="Nhập user cần tìm"
                                    required=""
                                    v-model="userNameSearching"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-12">
                        <div class="row align-items-center">
                            <div class="col-md-auto col-12">
                                <h6 class="bold mb-0">Tìm theo Object Id:</h6>
                            </div>
                            <div class="col-md col-12 mt-2 mt-md-0">
                                <input
                                    @change="getListRefund()"
                                    class="form-control input-light"
                                    type="text"
                                    placeholder="Nhập Id cần tìm"
                                    required=""
                                    v-model="idSearching"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-12 text-right">
                        <button
                            @click="requestRefundsAll()"
                            class="mt-2 mt-md-0 btn bold mr-2 btn-orange"
                            type="submit"
                        >
                            <i class="fa fa-dollar mr-1"></i>Hoàn tiền tất cả
                        </button>
                    </div>
                </div>
                <refundlistuser :data="data_pending"></refundlistuser>
            </div>
            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <refundhistory :data_success="data_success"></refundhistory>
            </div>
        </div>
    </div>
</template>
<script>
import { getListRefund, confirmRefund } from "../../../api/admin"
import refundlistuser from "./refund-list-user"
import refundhistory from "./refund-history"
import { catchError, formatNumber } from "../../../helpers"

export default {
    name: "refund-manager",
    components: { refundlistuser, refundhistory },
    data() {
        return {
            userNameSearching: "",
            idSearching: "",
            limit: 100,
            panel: "list",
            data: [],
            combined: false
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        isMobile() {
            return this.$store.state.app.isMobile
        },
        site() {
            return this.$store.state.site.site
        },
        reload() {
            return this.$store.state.app.reload
        },
        data_success() {
            return this.data.filter(item => {
                return item.is_confirm === 1 && item.status === 1
            })
        },
        data_pending() {
            if (this.combined) {
                return this.data
            }
            let successIds = this.data_success.map(item => {
                return item.id
            })
            return this.data.filter(item => {
                return successIds.indexOf(item.id) === -1
            })
        }
    },
    watch: {
        reload() {
            this.getListRefund()
        },
        panel(value) {
            if (value === "list") {
                this.getListRefund()
            }
        }
    },
    created() {
        this.getListRefund()
    },
    methods: {
        async getListRefund() {
            this.data = []
            this.data_success = []
            let search = this.userNameSearching
                ? "&username=" + this.userNameSearching
                : this.idSearching
                ? "&object_id=" + this.idSearching
                : ""
            if (this.userNameSearching || this.idSearching) {
                this.combined = true
            } else {
                this.combined = false
            }
            let data = await getListRefund(this.limit, search)
            if (data.data && data.status === 200) {
                this.data = data.data.map(item => {
                    item.username = item.user.username
                    item.tool = item.tool.name
                    item.status_html =
                        item.is_confirm === 1 && item.status === 0
                            ? "<span class='badge badge-pill badge-danger'>Đang xử lý</span>"
                            : item.status === 0
                            ? "<span class='badge badge-pill badge-warning text-white'>Chưa Hoàn Tiền</span>"
                            : item.is_confirm === 1 && item.status === 1
                            ? "<span class='badge badge-pill badge-success text-white'>Đã Hoàn Tiền</span>"
                            : item.status === 2
                            ? "<span class='badge badge-pill badge-primary text-white'>Trùng lệnh hoàn</span>"
                            : ""
                    item.old_coin =
                        "<span class='badge text-light badge-pill badge-danger'>" +
                        formatNumber(item.old_coin) +
                        "</span>"
                    item.new_coin =
                        "<span class='badge text-light badge-pill badge-success'>" +
                        formatNumber(item.new_coin) +
                        "</span>"
                    return item
                })
            }
        },
        requestRefundsAll: async function() {
            let data = this.data
            if (this.data.length > 0) {
                for (const log of data) {
                    let data = await confirmRefund(log.id)
                    if (data.status === 200 && data.success) {
                        this.$toastr.success("Hoàn tiền thành công")
                    } else {
                        this.$toastr.error(catchError(data))
                    }
                }
            }
        },
        handleShowPanel: async function(panel) {
            this.panel = panel
        }
    }
}
</script>
